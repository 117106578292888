import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
let  routes
const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
if (flag) {
  // 手机端
  routes = [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      component: () => import('@/views_mobile/home.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views_mobile/login.vue')
    },
    {
      path: '/guanyu',
      name: 'guanyu',
      component: () => import('@/views_mobile/guanyu.vue')
    },
    {
      path: '/product',
      name: 'product',
      component: () => import('@/views_mobile/product.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views_mobile/about.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views_mobile/contact.vue')
    }
  ]
} else {
  // PC端
  routes = [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      component: () => import('@/views/home.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login.vue')
    },
    {
      path: '/guanyu',
      name: 'guanyu',
      component: () => import('@/views/guanyu.vue')
    },
    {
      path: '/product',
      name: 'product',
      component: () => import('@/views/product.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/about.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/contact.vue')
    }
  ]
}



const router = new VueRouter({
  routes
})

export default router