const state = {
    token: '',
    userInfo: ''
}

const mutation = {

}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutation,
    actions
}